/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import AppStoreBadgeImage from "../Assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import GooglePlayBadgeImage from "../Assets/google-play-badge.webp";
import DiscordBadgeImage from "../Assets/discordButton.webp";

const CTAStylesD = styled.div`
  .discordBadge {
    font-family: "Montserrat";
    transition: 0.3s;
    img {
      height: 60px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }

  .badgeImg {
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const DiscordBadge = () => {
  return (
    <CTAStylesD>
      <a
        className="discordBadge"
        href={"https://discord.gg/46KQkj3TjU"}
        target="_blank"
        rel="noreferrer"
      >
        <img src={DiscordBadgeImage} className="badgeImg" alt="CTA Badge" />
      </a>
    </CTAStylesD>
  );
};

const CTAStyles = styled.div`
  .appStoreBadge {
    font-family: "Montserrat";
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  .badgeImg {
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const AppStoreBadge = ({ heightOverride }) => {
  return (
    <CTAStyles>
      <a
        className="appStoreBadge"
        href={"https://apps.apple.com/us/app/eden-savings/id1589645033"}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={AppStoreBadgeImage}
          className="badgeImg"
          alt="CTA Badge"
          style={{ height: heightOverride || "65px" }}
        />
      </a>
    </CTAStyles>
  );
};

const CTAStylesAS = styled.div`
  .appStoreBadge {
    font-family: "Montserrat";
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  .badgeImg {
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 530px) {
    .badgeImg {
      margin-top: -20px;
      margin-bottom: -20px;
    }
  }
`;

export const GooglePlayBadge = ({ heightOverride }) => {
  return (
    <CTAStylesAS>
      <a
        className="appStoreBadge"
        href={
          "https://play.google.com/store/apps/details?id=com.EdenFinancialTechnologies.Eden"
        }
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={GooglePlayBadgeImage}
          className="badgeImg"
          alt="CTA Badge"
          style={{ height: heightOverride || "90px" }}
        />
      </a>
    </CTAStylesAS>
  );
};
