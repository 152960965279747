import React from "react";
import styled from "styled-components";
import { DiscordBadge } from "../common";
import { AppStoreBadge } from "../common";
import { GooglePlayBadge } from "../common";
import {Helmet} from "react-helmet";
import { Colors, Breakpoints } from "../../Assets/styles";
import { TextGradient } from "../../Assets/mixins";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .ctas {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .gleam-imbed {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    box-shadow: 0px 0px 20px ${Colors.yellow};
    border-radius: 20px;
    padding: 48px 6vw;
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    margin-right: 20px;

    .title {
      ${TextGradient};
      font-family: "Montserrat";
      text-align: center;
      font-weight: 800;
      margin: 0;
      white-space: nowrap;
    }

    .tag {
      font-family: "Nanum Pen Script";
      font-size: 16px;
      padding: 2px 16px;
      white-space: nowrap;
      width: max-content;
      color: ${Colors.darker};
      background-color: ${Colors.assetRarity.mythic};
      border-radius: 20px;
      margin: 0;
    }
  }

  .body {
    max-width: 520px;
    font-size: 20px;
    padding-bottom: 32px;
  }

  @media (max-width: 980px) {
    .gleam-imbed {
      flex-wrap: wrap;
      justify-content: center;
    }
    .title-wrapper {
      display: flex;
      flex-direction: column-reverse;

      .title {
        font-size: 30px;
      }
    }

    .body {
      font-size: 18px;
      max-width: 380px;
    }
  }

  @media (max-width: ${Breakpoints.phone}) {
    .title-wrapper {
      .title {
        font-size: 24px;
      }
    }

    .body {
      font-size: 14px;
    }

    .ctas {
      margin: auto;
    }
  }
`;

export const CTAS = () => (
  <Styles>
    <div className="ctas">
      <AppStoreBadge/>
      <GooglePlayBadge/>
    </div>
    <DiscordBadge/>
  </Styles>
);

export const GleamImbed = () => (
  <Styles>
    <div className="gleam-imbed">
      <div>
        <div className="title-wrapper">
          <h2 className="title">Join the Eden Giveaway</h2>
          <p className="tag">Ending Soon</p>
        </div>
      </div>
      <div>
        <a className="e-widget no-button" href="https://gleam.io/aXS8F/launch-airpods-giveaway" rel="nofollow">Launch AirPods Giveaway</a>
        <Helmet>
          <script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>
        </Helmet>
      </div>
    </div>
  </Styles>
);
