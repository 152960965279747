import { initializeApp } from "firebase/app";

try {
  initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY_PROD,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN_PROD,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_PROD,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID_PROD,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
  });
} catch (error) {
  console.log(error);
}
