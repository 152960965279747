import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./firebase";

// import { Layout } from "./Components/Layout";
// import { NavigationBar } from "./Components/Navbar";
// import { Footer } from "./Components/Footer";

// import { Home } from "./Pages/Home";
// import { About } from "./Pages/About";
// import { Blog } from "./Pages/Blog";
// import { Waitlist } from "./Pages/Waitlist";
// import { WaitlistRules } from "./Pages/WaitlistRules";
// import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
// import { TermsOfService } from "./Pages/TermsOfService";
// import { NoMatch } from "./Pages/NoMatch";
// import { AuthProvider } from "./Auth";
// import { LeaderboardProvider } from "./LeaderboardProvider";

import ReactPixel from "react-facebook-pixel";
// import { Authenticator } from "./API/Authenticator";
// import Recaptcha from "./reCAPTCHA";

import { Colors } from "./Assets/styles";
import styled from "styled-components";
import { CTAS } from "./Components/Home/CTAS";

import footerL from "./Assets/Footer/FooterLeftC.webp";
import footerR from "./Assets/Footer/FooterRightC.webp";
import edenToCove from "./Assets/Home/edenToCove.png";

// const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};
ReactPixel.init("925116158866054", undefined, options);

class App extends Component {
  componentDidMount() {
    // Look for referral code in URL
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("ref")) {
      // Store referral code in local storage
      localStorage.setItem("referralCode", urlParams.get("ref"));
    }

    console.log("Referred by: " + localStorage.getItem("referralCode"));
  }

  render() {
    return (
      <Styles>
        <img src={edenToCove} style={{ maxWidth: "100%" }} />
        <h4 style={{ marginTop: 50 }}>{"We've rebranded!"}</h4>
        <button
          className="button"
          onClick={() => {
            location.href = location.href.replace("edensavings", "usecove");
          }}
        >
          Go to usecove.com
        </button>
        <CTAS />
        <img className="footerL" src={footerL} />
        <img className="footerR" src={footerR} />
        {/* <AuthProvider>
          <Authenticator>
            <LeaderboardProvider>
              <Recaptcha.widget />
              <NavigationBar />
              <Layout>
                <Router>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/blog" component={Blog} />
                    <Route path="/waitlist" component={Waitlist} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route
                      path="/terms-of-service"
                      component={TermsOfService}
                    />
                    <Route path="/waitlist-rules" component={WaitlistRules} />
                    <Route component={NoMatch} />
                  </Switch>
                </Router>
              </Layout>
              <Footer />
            </LeaderboardProvider>
          </Authenticator>
        </AuthProvider> */}
      </Styles>
    );
  }
}

export default App;

const Styles = styled.div`
  background-color: ${Colors.darker};
  color: ${Colors.mintWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;

  h4 {
    font-family: "Montserrat";
  }

  .button {
    background: linear-gradient(
      45deg,
      ${Colors.lightGreen},
      ${Colors.lightBlue}
    );
    border: none;
    border-radius: 5px;
    color: ${Colors.darker};
    font-family: "Montserrat";
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px;
    padding: 10px 20px;
    text-decoration: none;
    transition: 0.3s;
    z-index: 1001;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.75));

    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  .footerL {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }

  .footerR {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }

  @media (max-width: 880px) {
    .footerL {
      max-height: 200px;
    }
    .footerR {
      max-height: 200px;
    }
  }

  .footerWrapper {
    font-family: "Comfortaa";
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colors.darker};
    padding-top: 10px;
  }

  .footerLogo {
    width: 150px;
    margin: 30px;
  }

  .pagesListParent,
  .contactListParent {
    list-style: none;
    padding-inline-start: 0px;
    text-align: center;
    margin-bottom: 0px;
    z-index: 1001;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.75));
  }

  .pageListItem {
    color: ${Colors.mintWhite};
    z-index: 1001;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.75));

    &:hover {
      color: ${Colors.darkGreen};
    }
  }

  .socialParent {
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1001;
  }

  .social {
    margin: 10px;
    color: ${Colors.mintWhite};
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.75));
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  .contactListParent {
    color: ${Colors.mintWhite};
    margin-bottom: 25px;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.75));
  }

  .copyrightText {
    color: ${Colors.mintWhite};
    margin: 5px;
    text-align: center;
    padding-bottom: 20px;
    z-index: 1001;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.75));
  }
`;
