export const Colors = {
  darkGreen: "#60B057",
  lightGreen: "#7ACF3F",
  dark: "#343A40",
  darker: "#232526",
  mintWhite: "#F4F7F5",
  gray: "#9FA3A4",
  paleGold: "#ffcc99",
  errorRed: "#FFCCCC",
  red: "#FF5733",
  blue: "#0099FF",
  lightBlue: "#00C8F0",
  discordPurple: "#7579E2",
  yellow: "#FFD700",
  blueGreenGradient: `linear-gradient(to right, #7ACF3F, #00C8F0)`,
  assetRarity: {
    common: "#707070",
    uncommon: "#4EB143",
    rare: "#1669E6",
    epic: "#E519D7",
    legendary: "#FFD700",
    mythic: "#BC1515"
  },
  leaderboard: {
    1: "#FFD700",
    2: "#BEC2CB",
    3: "#CD7F32",
    rest: "#FFFFFF",
    me: "#7ACF3F"
  }
};

export const Breakpoints = {
  phone: "480px",
  tablet: "768px",
  desktop: "1200px"
};
